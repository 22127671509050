<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Checklist</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="model-form">
          <v-row align="center" justify="center">
            <v-col cols="6">
              <strong>{{ fields.name }}</strong>
            </v-col>

            <v-col cols="6">
              <v-switch
                label="Completed"
                v-model="fields.completed"
                inset
                :error="errors.hasOwnProperty('completed')"
                :error-messages="errors['completed']"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-textarea
                label="Notes"
                v-model="fields.notes"
                type="text"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('notes')"
                :error-messages="errors['notes']"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="model-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },

  data() {
    return {
      fields: {
        notes: null,
        completed: false,
        name: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  methods: {
    openForm: function (checklistId, model = null) {
      if (model !== null) {
        this.model = model;
        this.checklistId = checklistId;
        this.fields.completed = model.completed;
        this.fields.name = model.checklist_items.name;
        this.fields.notes = model.notes;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        detailId: this.model.id,
        fields: this.fields,
      };

      if (this.type == "checkout") {
        this.dispatch = "sbpm/checkoutStore/saveDetail";
      } else if (this.type == "enquiry") {
        this.dispatch = "sbpm/enquiriesStore/saveDetail";
      } else {
        this.dispatch = "sbpm/moveoutStore/saveDetail";
      }

      this.$store
        .dispatch(this.dispatch, payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields = {
        notes: null,
        completed: false,
        name: null,
      };
    },
  },
};
</script>
